<template>
    <div class="Register">
        <div class="logo">
            <img class="logo-img" :src="require('@/assets/img/logo.png')" />
        </div>

        <p class="title">创建账户</p>

        <van-form @submit="Register" class="user">
            <van-cell-group inset>
                <p class="user-name">用户名</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben48"></span>
                    <van-field v-model="username" name="username" placeholder="请输入用户名" />
                </div>

                <p class="user-name">登录密码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben44"></span>
                    <van-field v-model="password" type="password" name="password" placeholder="请输入密码" />
                </div>

                <p class="user-name">手机号码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben41"></span>
                    <van-field v-model="cellphone" name="cellphone" placeholder="请输入手机号" />
                </div>

                <p class="user-name">昵称</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                    <van-field v-model="nickname" name="nickname" placeholder="请输入昵称" />
                </div>

                <p class="user-name">密保问题</p>
                <div class="flex-row">
                    <span class="label">问题1</span>
                    <span class="text">{{ question1 }}</span>
                </div>
                <div class="flex-row">
                    <span class="label">答案1</span>
                    <van-field v-model="que1_answer" name="que1_answer" placeholder="请输入" />
                </div>
                <div class="flex-row">
                    <span class="label">问题2</span>
                    <span class="text">{{ question2 }}</span>
                </div>
                <div class="flex-row">
                    <span class="label">答案2</span>
                    <van-field v-model="que2_answer" name="que2_answer" placeholder="请输入" />
                </div>

                <p class="user-name">验证码</p>
                <div class="flex-row">
                    <span class="iconfont icon-a-iconpay_huaban1fuben12"></span>
                    <van-field v-model="imgcode" name="imgcode" placeholder="请输入验证码" />

                    <img class="img-code" :src="imgSrc" @click="getImgCode" />
                </div>
            </van-cell-group>

            <div class="user-btn">
                <van-button block type="primary" native-type="submit">确定</van-button>
            </div>
        </van-form>

        <p class="login" @click="router.push('/Login')">已有账户，立即登录</p>
    </div>
</template>

<script>
import { reactive, toRefs, nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { GetImgCode, userRegister, GetPrivateQuestion } from '@/api'
import md5 from 'js-md5'
import { Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            username: '',
            password: '',
            cellphone: '',
            nickname: '',
            imgcode: '',
            imgSrc: '',
            que1: '',
            question1: '',
            que1_answer: '',
            que2: '',
            question2: '',
            que2_answer: ''
        })

        const router = useRouter()
        const store = useStore()

        // 获取图形验证码
        const getImgCode = () => {
            GetImgCode().then(res => (state.imgSrc = res.data.image))
        }

        // 默认先获取一次
        getImgCode()

        const getMibaoList = () => {
            GetPrivateQuestion().then(res => {
                state.que1 = res.data[0].ID
                state.question1 = res.data[0].SHOW_NAME
                state.que2 = res.data[1].ID
                state.question2 = res.data[1].SHOW_NAME
            })
        }

        getMibaoList()

        const validateForm = params => {
            if (!params.username) {
                Toast('请输入用户名')
                return false
            }
            if (!params.password) {
                Toast('请输入密码')
                return false
            }
            if (!params.cellphone) {
                Toast('请输入手机号码')
                return false
            }
            if (!params.nickname) {
                Toast('请输入昵称')
                return false
            }
            if (!params.que1_answer) {
                Toast('请输入答案1')
                return false
            }
            if (!params.que2_answer) {
                Toast('请输入答案2')
                return false
            }
            if (!params.imgcode) {
                Toast('请输入验证码')
                return false
            }
            return true
        }

        // 注册
        const Register = params => {
            if (!validateForm(params)) {
                return
            }
            userRegister({
                username: params.username,
                password: md5(params.password),
                cellphone: params.cellphone,
                imgcode: params.imgcode,
                nickname: params.nickname,
                que1: state.que1,
                que1_answer: params.que1_answer,
                que2: state.que2,
                que2_answer: params.que2_answer
            }).then(res => {
                if (res.status) {
                    Toast.success('注册成功！')
                } else {
                    getImgCode()
                    Toast.fail(res.message)
                }
            })
        }

        nextTick(() => {
            document.removeEventListener('touchmove', window.touchmoveListener, { passive: false })
        })
        onUnmounted(() => {
            document.addEventListener('touchmove', window.touchmoveListener, { passive: false })
        })

        return {
            ...toRefs(state),
            router,
            Register,
            getImgCode
        }
    }
}
</script>

<style lang="scss" scoped>
.Register {
    min-height: 0 !important;
    text-align: center;
    color: #000000;

    .logo {
        margin-top: h(12);
        padding: 0 w(30);

        .logo-img {
            margin-right: w(7);
            vertical-align: -15px;
        }
    }

    .title {
        padding: 0 w(30);
        font-size: 20px;
        margin-top: h(30);
        font-weight: normal;
        font-weight: normal;
    }

    .user {
        padding: 0 w(30);
        margin: h(20) 0 h(34) 0;

        .flex-row {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            height: h(40);
            line-height: h(50);

            .label {
                width: 5em;
                text-align: left;
            }

            .text {
                flex: 1;
                text-align: left;
            }

            .img-code {
                max-height: 100%;
                height: h(50);
                position: absolute;
                right: 0;
            }
        }

        .user-name {
            margin-top: h(21);
            text-align: left;
            color: #333333;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }

        .user-btn {
            margin-top: h(30);
            font-size: 18px;
        }
    }

    .login {
        padding: 0 w(30);
        margin-top: h(10);
        text-align: right;
        color: #6d88fb;
        font-size: 14px;
    }
}
</style>
